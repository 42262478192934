import React from 'react'
import { Link } from 'gatsby'
import { func, bool } from "prop-types";
import styled from "styled-components";

// const StyledContainer = styled(Row)`
//   background-color: var(--white-1);
//   box-shadow: var(--white-1) 0px 10px 30px -10px;
//   padding: 0;
//   @media screen and (max-width: 768px) {
//     position: fixed;
//     z-index: 10;
//     width: 50vw;
//     top: 0;
//     bottom: 0;
//     background-color: var(--white-1);
//     text-align: center;
//     right: 0;
//     overflow: hidden;
//     box-shadow: var(--primary-color) 0px 10px 30px -10px;
//   }
//   transform: ${({ open }) => open ? "translateX(0%)" : "translateX(100%)"};
//   visibility: ${({ open }) => open ? "visible" : "hidden"};
//   transition: all 0.3s cubic-bezier(0.645,0.045,0.355,1) 0.1s;
// `;


const Overlay = styled.div`
  background-color: transparent; 
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: ${({ open }) => open ? "1" : "0"};
  visibility: ${({ open }) => open ? "visible" : "hidden"};
  transition: all 0.3s cubic-bezier(0.645,0.045,0.355,1) 0.1s;
`;

const ListNavigation = styled.ul`
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 1rem 0;
  margin: 0;
  font-size: 1.375rem;
  @media screen and (max-width: 768px) {
    padding-top: 0;
    flex-direction: column;
    align-items: center;
    height: 40vh;
    justify-content: space-between;
  }
  .active {
    font-weight: 500;
  }
  a {
    padding: 0.5rem 0;
    color: var(--white-1);
    &:hover {
      text-decoration: none;
    }
  }
`;

const ListItem = styled.li`
  display: inline-flex;
  align-items: center;
  margin: 0 1em;
`

const NavigationContainer = styled.nav`
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
    position: fixed;
    z-index: 11;
    width: 50vw;
    top: 0;
    bottom: 0;
    background-color: var(--primary-color);
    text-align: center;
    right: 0;
    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -1px;
    transform: ${({ open }) => open ? "translateX(0%)" : "translateX(100%)"};
    visibility: ${({ open }) => open ? "visible" : "hidden"};
    transition: all 0.3s cubic-bezier(0.645,0.045,0.355,1) 0.1s;
  }
`;

const Navigation = ({ setOpen, open }) => {

  return (
    <>
      <Overlay open={open} onClick={() => setOpen(false)} />
      <NavigationContainer open={open}>
        <ListNavigation>
          <ListItem>
            <Link activeClassName="active" to="/">
              Home
            </Link>
          </ListItem>
          <ListItem>
            <Link activeClassName="active" to="/projects/">Projects</Link>
          </ListItem>
          <ListItem>
            <Link activeClassName="active" to="/contact/">Contact</Link>
          </ListItem>
          {/* <ListItem>
            <Link activeClassName="active" to="/about/">About</Link>
          </ListItem> */}
        </ListNavigation>

      </NavigationContainer>
    </>
  );
}

Navigation.defaultProps = {
  setOpen(){},
  open: false
};

Navigation.propTypes = {
  setOpen: func,
  open: bool
};

export default Navigation;