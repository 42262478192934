import React from 'react'
import "../styles/main.sass"
import './base.css'
import { Container, Row, Col } from "react-bootstrap"
import { SEO, useSEO } from "gatsby-plugin-seo";
import Header from './Header'

const Template = ({ children }) => {
  
  const { siteUrl } = useSEO();

  return (
    <>
      <SEO
        title="Alberto Perez | Front End Developer"
        description="Front End Developer | Portfolio"
        pagePath="/"
        schema={`{
              "@context": "http://schema.org",
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Organization",
                "name": "Bpcodes",
                "image": "${siteUrl}"
              }
            }`}
      />
      <Header />
      <Container>
        {children}
        <Row>
          <Col>
            <p className="mt-4 mb-3 text-center">
              Designed & Built by Alberto Perez
            </p>
          </Col>
        </Row>
        
      </Container>
    </>
  );
}

export default Template
