import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col } from 'react-bootstrap';
import Navigation from "./Navigation";
import ButtonMenu from "./ButtonMenu";

const StyledContainer = styled(Row)`
  padding: 0;
`;

const Logo = styled.h2`
  @media screen and (max-width: 768px){
    transform: translateY(1rem);
  }
`;

function headerScroll(){

  const doc = document.documentElement;
  const w = window;

  let prevScroll = w.scrollY || doc.scrollTop;
  let curScroll = null;
  let direction = 0;
  let prevDirection = 0;

  const header = document.getElementById("header-container");

  const checkScroll = function() {

    /*
    ** Find the direction of scroll
    ** 0 - initial, 1 - up, 2 - down
    */

    curScroll = w.scrollY || doc.scrollTop;
    

    if (curScroll > prevScroll) { 
      //scrolled up
      direction = 2;
    }
    else if (curScroll < prevScroll) { 
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }
    
    prevScroll = curScroll;
  };

  const toggleHeader = function(direction, curScroll) {
    if (direction === 2 && curScroll > 74.88) { 

      header.classList.add('hide-header');
      prevDirection = direction;
    }
    else if (direction === 1) {
      header.classList.remove('hide-header');
      prevDirection = direction;
    }
    
  };
  window.addEventListener('scroll', checkScroll);
  return checkScroll;

}

const Header = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const { checkScroll } = headerScroll();
    return () => {
      window.removeEventListener("scroll", checkScroll)
    }
  }, [])
  
    return (
      <div className="header-navigation shadow" id="header-container">
        <div className="container">
          <ButtonMenu open={open} setOpen={setOpen} />
          <StyledContainer open={open} className="d-flex align-items-center">
            <Col className="" xs={12} md={2}>
              <Logo className="mb-0">BpCodes</Logo>
            </Col>
            <Col xs={12} md={{ span: 7, offset: 3 }}>
              <Navigation open={open} setOpen={setOpen} />
            </Col>
          </StyledContainer>

        </div>
      </div>
    );
};

export default Header